import React from "react";

import {
  Container,
  Box,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


// Customizable Area End

import SalesForceIntegrationListController, {
  ISalesForce,
  Props,
} from "./SalesForceIntegrationListController";

export default class SalesForceIntegrationList extends SalesForceIntegrationListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  contactlist = (item: ISalesForce) => {
    return (
      <div
        style={{
          border: '10px solid rgba(0, 0, 0, 0.05)',
          width: '100%',
          marginTop: 10,
          paddingBottom: 15,
          borderWidth: 0.4, padding: 10,
        }}
      >

        <div style={{flexDirection:'row', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
        <div style={{ flexDirection: 'column' }}>
          <div  >
            <text
              style={{
                fontSize: 15,
                marginTop: 1,
              }}
            >
              Name : {item.username}
            </text>
          </div>
          <div>

            <text
              style={{
                fontSize: 15,
              }}
            >
              Phone : {item.phone_no}
            </text>
          </div>
          <div>
            <text
              style={{
                fontSize: 15,
              }}
            >
              Email : {item.email}
            </text>
          </div>
        </div>
        <div style={{display:'flex',flexDirection:'row', justifyContent:'center', alignItems:'center'}} >
          <button style={{backgroundColor:'blue'}}  data-test-id={"onEdit"}
            onClick={() => this.navigateToEditDetails(item)}
          >Edit</button>
          <button style={{marginLeft:'20px', backgroundColor:'blue'}} onClick={() => this.deleteData(item)} data-test-id={"onDelete"}>Delete</button>
        </div>

        </div>
      </div>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <div><text style={{ fontSize: 25, marginTop: '10px' }} >User Subscribers</text></div>
            {this.state.getListingDetails.map((item: ISalesForce) => (
              this.contactlist(item)
            ))}

          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: "10px"
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
