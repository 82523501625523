Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.patchMethod='PATCH';
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Subscriber Form";
exports.labelBodyText = "View list";
exports.nameLabel="Name";
exports.phoneLabel='Phone Number';
exports.emailLabel="Email";
exports.btnExampleTitle = "SUBMIT";
exports.listingLabel="User Subscribers";
exports.updateLabel="UPDATE";
exports.flatName="Name : ABC";
exports.flatPhone="Phone Number : 9999999999";
exports.flatEmail="Email : abc@gamil.com";
exports.flatEdit="Edit";
exports.flatDel="Delete"
exports.accessTokenEndpoint='bx_block_salesforceintegration2/accounts/outh_login'
exports.createEndpoint='bx_block_salesforceintegration2/accounts/';
// Customizable Area End