import React from "react";

import {
  Container,
  Box,
  Input,
  Typography
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


// Customizable Area End

import Salesforceintegration2Controller, {
  Props,
  configJSON,
} from "./Salesforceintegration2Controller";

export default class Salesforceintegration2 extends Salesforceintegration2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
 
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>


            <Box sx={webStyle.inputStyle}>

              <Input
                data-test-id={"txtInputName"}
                onBlur={() => this.onCreateNameBlurVal()}
                onFocus={() => this.onfocusName()}
                type={"text"}
                placeholder={configJSON.nameLabel}
                fullWidth={true}
                disableUnderline={true}
                value={this.state.createName}
                
                onChange={(tName) => this.onCreateNameText(tName.target?.value)}
              />
            </Box>
            {this.webNameLogic() &&  <text style={{ color: 'red', bottom: 6 }}>{this.state.errorName}</text>}
            <Box sx={webStyle.inputStyle}>

              <Input
                data-test-id={"txtInputPhone"}
                onBlur={() => this.onCreatePhoneBlurVal()}
                onFocus={() => this.onfocusPhone()}
                type={"number"}
                placeholder={configJSON.phoneLabel}
                fullWidth={true}
                disableUnderline={true}
                value={this.state.createPhone}
              onChange={(tPhone) => this.onCreatePhoneText(tPhone?.target?.value)}
              />
            </Box>
            {this.webPhoneLogic() &&  <text style={{ color: 'red', bottom: 6 }}>{this.state.errorPhone}</text>}
            <Box sx={webStyle.inputStyle}>

              <Input
                data-test-id={"txtInputEmail"}
                onBlur={() => this.onCreateEmailBlurVal()}
                onFocus={() => this.onfocusEmail()}
                type={"text"}
                placeholder={configJSON.emailLabel}
                fullWidth={true}
                disableUnderline={true}
                value={this.state.createEmail}
                 onChange={(tEmail) => this.onCreateEmailText(tEmail?.target?.value)}
              />
            </Box>
            {this.webEmailLogic() &&  <text style={{ color: 'red', bottom: 6 }}>{this.state.errorEmail}</text>}

            <div
            ><button data-test-id="btnAddExample" onClick={() =>
              this.isValidateSubmit()
            } style={webStyle.buttonStyle}>Submit</button></div>

            <div><button data-test-id="viewTestId" style={webStyle.buttonStyle}
              onClick={() => this.listOnPress()}
            >{configJSON.labelBodyText}</button></div>


          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
  },
  inputStyle: {

    border: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: "10px",
    padding:'10px'
  },
  buttonStyle: {
    width: "350px",
    height: "50px",
    marginTop: "40px",
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'blue',
    fontSize: 15
  },
};
// Customizable Area End
