import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Platform, ToastAndroid } from "react-native";
export interface ISalesForce {
  id: number;
  sf_acc_id: string;
  email: string;
  phone_no: number;
  username: string;
  created_at: string;
  updated_at: string;
}
export interface ISalesForceDelete {
  message: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  name: string;
  email: string;
  phone: string;
  getListingDetails: ISalesForce[];
  createdData: string;
  getNameListing: string;
  token: string;
  deleteMessage: string;
  isLoading: boolean;
  getAccessToken: string;
  delArray: number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SalesForceIntegrationListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getListingApiId: string = ' ';
  deleteApiId: string = ' ';
  getDelAccessTokenId: string = ' ';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      name: '',
      email: '',
      phone: '',
      getListingDetails: [],
      createdData: '',
      getNameListing: '',
      token: '',
      deleteMessage: '',
      isLoading: false,
      getAccessToken: '',
      delArray: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );


    if (apiRequestCallId === this.getDelAccessTokenId) {
      this.setState({
        token: responseJson.access_token,
      });
    }

    if (
      apiRequestCallId === this.getListingApiId
    ) {

      this.setState({
        getListingDetails: responseJson,
        isLoading: false
      });
    }

    if (
      apiRequestCallId === this.deleteApiId
    ) {
      this.setState({
        deleteMessage: responseJson,
        isLoading: false,
      });
      if (Platform.OS === 'web') {
        window.alert('Details deleted successfully')
      }
      this.getListingData()
    }

   
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getAccessToken();
    this.getListingData();
    if (Platform.OS === 'web') {
      window.addEventListener("focus",  () => {
        this.getListingData();
      });
    }
    else{
      this.props.navigation.addListener("willFocus",  () => {
        this.getListingData();
      });
    }
  }


  getAccessToken() {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDelAccessTokenId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accessTokenEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  getListingData = async () => {
    this.setState({
      isLoading: true
    })

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListingApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteData = (item: { id: number }) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const body = {
      "access_token": this.state.token
    };

    this.deleteApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createEndpoint}/${item.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.flatDel
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  navigateToEditDetails = (item: { id: number, username: string, phone_no: number, email: string }) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "SalesForceIntegrationEdit"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      passId: JSON.stringify(item.id),
      passName: item.username,
      passPhone: JSON.stringify(item.phone_no),
      passEmail: item.email
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  onToast = () => {
    ToastAndroid.show('Details deleted successfully !', ToastAndroid.SHORT);
  }
  // Customizable Area End
}
