import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Keyboard, Platform, ToastAndroid } from "react-native";
export interface ISalesForceCreate {
  id: number;
  sf_acc_id: string;
  email: string;
  phone_no: number;
  password: string;
  username: string;
  created_at: string;
  updated_at: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  createName: string;
  createEmail: string;
  createPhone: string;
  getAccessToken: string;
  createdData: string;
  authToken: string;
  errorName: string
  errorEmail: string;
  errorPhone: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Salesforceintegration2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAccessTokenId: string = '';
  createDataApiCall: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      createName: '',
      createEmail: '',
      createPhone: '',
      getAccessToken: '',
      createdData: '',
      authToken: '',
      errorName: '',
      errorEmail: '',
      errorPhone: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )

    if (apiRequestCallId === this.getAccessTokenId) {
      this.setState({
        getAccessToken: responseJson.access_token,
      });

    }

    if (
      apiRequestCallId === this.createDataApiCall
    ) {
      this.setState({
        createdData: responseJson
      });
      if (Platform.OS === 'web') {
        window.alert('Details submitted successfully')
      }
      this.listOnPress();
      if (Platform.OS === 'android') {
        this.onToast()

      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();

    this.getAccessToken();
    if (Platform.OS === 'web') {
      window.addEventListener("focus", () => {
        this.getAccessToken();
      });
    }
  }

  getAccessToken() {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAccessTokenId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accessTokenEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }



  async createData() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const body = {
      "data": {
        "attributes": {
          "name": "Test Account",
          "phone_no": this.state.createPhone,
          "email": this.state.createEmail,
          "username": this.state.createName
        }
      },
      "access_token": this.state.getAccessToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createDataApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }


  onToast = () => {
    ToastAndroid.show('Details submitted successfully !', ToastAndroid.SHORT);
  }

  listOnPress = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "SalesForceIntegrationList"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), null);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  }
  onCreateNameText(text: string) {
    this.setState({ createName: text })
  }
  onCreatePhoneText(text: string) {
    this.setState({ createPhone: text })
  }
  onCreateEmailText(text: string) {
    this.setState({ createEmail: text })
  }

  onCreateNameBlurVal() {
    const nameRegex = /^[A-Za-z ]+$/;
    const isValidEmail = nameRegex.test(this.state.createName);
    if (!isValidEmail) {
      this.setState({ errorName: 'Invalid Name' })
    }
  }
  onCreateEmailBlurVal() {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const isValidEmail = emailRegex.test(this.state.createEmail);
    if (!isValidEmail) {
      this.setState({ errorEmail: 'Invalid Email' })
    }
  }

  onCreatePhoneBlurVal() {
    if (this.state.createPhone?.length != 10) {
      this.setState({ errorPhone: 'Invalid Phone Number' })
    }
  }

  functionOne() {
    this.onCreateNameBlurVal();
    this.functionTwo();

  }
  functionTwo() {
    this.onCreateEmailBlurVal();
    this.functionThree()
  }
  functionThree() {
    this.onCreatePhoneBlurVal();
    this.functionFour()
  }

  functionFour() {
    this.functionFive()
  }

  functionFive() {
    if (this.state.createName?.length > 0 && this.state.createEmail?.length > 0 && this.state.createPhone?.length > 0 && this.state.errorName == '' && this.state.errorPhone == '' && this.state.errorEmail == '') {
      this.createData()
    }
    
  }
  webEmailLogic(){
    return this.state.errorEmail.length > 0; 
  }
  webNameLogic(){
    return this.state.errorName.length > 0;
  }

  webPhoneLogic(){
    return this.state.errorPhone.length > 0;
  }
  onfocusEmail(){
    this.setState({ errorEmail: '' })
  }
  onfocusName(){
    this.setState({ errorName: '' })
  }
  onfocusPhone(){
    this.setState({ errorPhone: '' })
  }
  isValidateSubmit() {
    this.functionOne()
    Keyboard.dismiss()

  }


  // Customizable Area End
}
