import React from "react";

import {
  Container,
  Box,
  Input,
  Typography
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


// Customizable Area End

import SalesForceIntegrationEditController, {
  Props,
  configJSON,
} from "./SalesForceIntegrationEditController";

export default class SalesForceIntegrationEdit extends SalesForceIntegrationEditController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyledata.mainWrapperdata}>
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>

            <Box sx={webStyledata.inputStyle}>
              <Input
                fullWidth={true}
                disableUnderline={true}
                placeholder={configJSON.nameLabel}
                value={this.state.passName}
                onChange={(tName) => this.editNameText(tName.target?.value)}
                data-test-id={"txtInputName"}
                onBlur={() => this.onCreateNameBlurVal()}
                onFocus={() => this.onfocusName()}
                type={"text"}
              />
            </Box>
            {this.webNameLogic() &&  <text style={{ color: 'red', bottom: 5 }}>{this.state.errorName}</text>}
            <Box sx={webStyledata.inputStyle}>

              <Input
                data-test-id={"txtInputPhone"}
                onBlur={() => this.onCreatePhoneBlurVal()}
                onFocus={() => this.onfocusPhone()}
                type={"number"}
                placeholder={configJSON.phoneLabel}
                fullWidth={true}
                disableUnderline={true}
                value={this.state.passPhone}
                onChange={(tPhone) => this.editPhoneText(tPhone?.target?.value)}
              />
            </Box>
            {this.webPhoneLogic() &&  <text style={{ color: 'red', bottom: 5 }}>{this.state.errorPhone}</text>}
            <Box sx={webStyledata.inputStyle}>

              <Input
                data-test-id={"txtInputEmail"}
                onBlur={() => this.onCreateEmailBlurVal()}
                onFocus={() => this.onfocusEmail()}
                type={"text"}
                placeholder={configJSON.emailLabel}
                fullWidth={true}
                disableUnderline={true}
                value={this.state.passEmail}
                onChange={(tEmail) => this.editEmailText(tEmail?.target?.value)}
              />
            </Box>
            {this.webEmailLogic() &&  <text style={{ color: 'red', bottom: 5 }}>{this.state.errorEmail}</text>}

            <div data-test-id="btnAddExample"
              onClick={() => {
                this.isValidateSubmit()
              }}
            ><button style={webStyledata.buttonStyle}>{configJSON.updateLabel}</button></div>


          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyledata = {
  buttonStyle: {
    justifyContent: 'center',
    backgroundColor: 'blue',
    fontSize: 15,
    width: "350px",
    height: "50px",
    marginTop: "40px",
    alignItems: 'center',
  },
  inputStyle: {
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: "10px",
    padding:'10px',
    border: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "40px",
    display: "flex"
  },
  mainWrapperdata: {
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
    display: "flex",
    fontFamily: "Roboto-Medium"
  },


};
// Customizable Area End
