import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Keyboard, Platform, ToastAndroid } from "react-native";
export interface ISalesForceEdit {
    id: number;
    sf_acc_id: string;
    email: string;
    phone_no: number;
    password: string;
    username: string;
    created_at: string;
    updated_at: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    passName: string;
    passPhone: string;
    passEmail: string;
    passId: string;
    authToken: string;
    errorName: string;
    errorPhone: string;
    errorEmail: string;
    editedData: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SalesForceIntegrationEditController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getEditAccessTokenId: string = '';
    editDataApiCall: string = ''
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            passName: '',
            passPhone: '',
            passEmail: '',
            passId: '',
            authToken: '',
            errorName: '',
            errorPhone: '',
            errorEmail: '',
            editedData: ''
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const termsCondsData = message.getData(
                getName(MessageEnum.SessionResponseData)
            );
            if (termsCondsData) {
                const { passName, passPhone, passEmail, passId } = termsCondsData;
                this.setState({ passName: passName, passPhone: passPhone, passEmail: passEmail, passId: passId });
            }
        }

        else if (apiRequestCallId === this.getEditAccessTokenId) {
            this.setState({
                authToken: responseJson.access_token,
            });
        }
        else if (
            apiRequestCallId === this.editDataApiCall
        ) {
            this.setState({
                editedData: responseJson
            });

            this.editAlertHandler();
        }



        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getAccessToken()
    }

    editAlertHandler = async () => {
        setTimeout(() => {
            this.onUpdatePress();
            if (Platform.OS === 'web') {
                window.alert('Details updated successfully')
            }
            else {
                ToastAndroid.show('Details updated successfully !', ToastAndroid.SHORT)
            }
        }, 2000)
        if (Platform.OS === 'android') {
            Keyboard.dismiss()
        }
    };

    getAccessToken() {

        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getEditAccessTokenId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.accessTokenEndpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    onUpdatePress() {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "SalesForceIntegrationList"
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), { "passId": "65", "passName": "View list check", "passPhone": "8045631201", "passEmail": "viewlist@gmail.com" });
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

        this.send(message);

    }



    editData = () => {

        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };
        const body = {
            "data": {
                "attributes": {
                    "name": "Test Account",
                    "phone_no": this.state.passPhone,
                    "email": this.state.passEmail,
                    "username": this.state.passName
                }
            },
            "access_token": this.state.authToken
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.editDataApiCall = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.createEndpoint}/${this.state.passId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    editNameText(text: string) {
        this.setState({ passName: text })
    }
    editPhoneText(text: string) {
        this.setState({ passPhone: text })
    }
    editEmailText(text: string) {
        this.setState({ passEmail: text })
    }

    onCreateNameBlurVal() {
        const nameRegex = /^[A-Za-z ]+$/;
        const isValidEmail = nameRegex.test(this.state.passName);
        if (!isValidEmail) {
            this.setState({ errorName: 'Invalid Name' })
        }
    }
    onCreateEmailBlurVal() {
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
        const isValidEmail = emailRegex.test(this.state.passEmail);
        if (!isValidEmail) {
            this.setState({ errorEmail: 'Invalid Email' })
        }
    }

    onCreatePhoneBlurVal() {
        if (this.state.passPhone?.length != 10) {
            this.setState({ errorPhone: 'Invalid Phone Number' })
        }
    }

    functionOne() {
        this.onCreateNameBlurVal();
        this.functionTwo();

    }
    functionTwo() {
        this.onCreateEmailBlurVal();
        this.functionThree()
    }
    functionThree() {
        this.onCreatePhoneBlurVal();
        this.functionFour()
    }

    functionFour() {
        this.functionFive()
    }

    functionFive() {
        if (this.state.passName?.length > 0 && this.state.passEmail?.length > 0 && this.state.passPhone?.length > 0 && this.state.errorName == '' && this.state.errorPhone == '' && this.state.errorEmail == '') {
            this.editData()
        }

    }
    onfocusEmail(){
        this.setState({ errorEmail: '' })
      }
      onfocusName(){
        this.setState({ errorName: '' })
      }
      onfocusPhone(){
        this.setState({ errorPhone: '' })
      }
    webEmailLogic(){
        return this.state.errorEmail.length > 0; 
      }
      webNameLogic(){
        return this.state.errorName.length > 0;
      }
    
      webPhoneLogic(){
        return this.state.errorPhone.length > 0;
      }
    isValidateSubmit() {
        this.functionOne()
        Keyboard.dismiss()

    }

    // Customizable Area End
}
